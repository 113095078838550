import React, {Fragment, useEffect, useState, useRef } from "react";
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react';
import { Navigation } from "swiper";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";
import Container from '@mui/material/Container';
import { useWeb3React } from '@web3-react/core'
import Fade from 'react-reveal/Fade'
import { Button } from '@mui/material';
import './Stack.css'
import useRefresh from './redux/Refresh/useRefresh'

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;


  const space = <Fragment>&nbsp;&nbsp;</Fragment>;

const baseURI = "tokens/"

export const StyledButton = styled.button`
  font-family: 'coder';
  padding: 10px;
  font-size: 24px;
  border-radius: 6px;
  border: none;
  background-color: #202443;
  padding: 10px;
  letter-spacing: 6px;
  // font-weight: bold;
  color: white;
  width: 450px;
  height: 50px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 6px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  :hover {
    background-color: #4F7DFD
  }
  @media (max-width: 580px) {
    width: 350px;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  border-radius: 10px;
  border: none;
  background-color: #202443;
  padding: 10px;
  // font-weight: bold;
  font-size: 50px;
  color: white;
  width: 60px;
  height: 60px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
  :hover {
    background-color: #4F7DFD
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  // margin: auto;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const ResponsiveWrapperHeader = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  display: inline;
  width: 160px;
  @media (max-width: 767px) {
    width: 100px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  width: 300px;
  @media (min-width: 900px) {
    width: 350px;
  }
  @media (min-width: 1000px) {
    width: 400px;
  }
  transition: width 0.5s;
`;


export const StyledLink = styled.a`
  color: var(--secondary);
  text-decoration: none;
  :hover {
    color: red;
  }
`;

export const WalletBox = styled.div`
  text-decoration: none;
  border-radius: 10px;
  border: 2px solid white;
  background-color: #202443;
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  width: 250px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  -moz-box-shadow: 0px 4px 0px -2px rgba(250, 250, 250, 0.3);
  :hover {
    background-color: #4F7DFD
  }
  @media (max-width: 580px) {
    margin-top: 20px;
    margin-left: 40px;
  }
`;

function App() {
  const [isApproved, setIsApproved] = useState(false)
  const [fetchFlag, setFetchFlag] = useState(true);
  const [pendingTx, setpendingTx] = useState(false)
  const { slowRefresh, fastRefresh } = useRefresh()
  console.log("beast refresh", slowRefresh)

  const [unstakedinfo, setUnStakedInfo] = useState([])
  const [stakedinfo, setStakedInfo] = useState([])
  const [reward, setReward] = useState(0)

  const [selectedUnStakedTokenIds, setSelectedUnStakedTokenIds] = useState([])
  const [selectedStakedTokenIds, setSelectedStakedTokenIds] = useState([])
  const [sizeArrayofStakedTokens, setSizeArrayofStakedTokens] = useState([])

  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [walletAddress, setAddress] = useState("Not Connected");
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`PUBLIC SALE - You can mint up to 10 per Tx without a cap!`);
  const [amount, setamount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    NFT_CONTRACT_ADDRESS: "",
    REWARD_CONTRACT_ADDRESS: "",
    STAKING_CONTRACT_ADDRESS: "",
    NFT_SCAN_LINK: "",
    REWARD_SCAN_LINK: "",
    STAKING_SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    WL_COST: 0,
    DISPLAY_COST: 0,
    WL_Display: 0,
    GAS_LIMIT: 0,
    WEB_LINK: "",
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const getData = () => {
    if (blockchain.account !== "" && blockchain.nftContract !== null) {
      setAddress(blockchain.account.substring(0,4) + "..." + blockchain.account.substring(38,42));
      console.log(blockchain.account.substring(0,4) + "..." + blockchain.account.substring(38,42));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  const fetchUnStakedInfo = async () => {
    const tokenIds = []
    const balance = await blockchain.nftContract.methods.balanceOf(blockchain.account).call()
    let a1 = 0
    /* eslint-disable no-await-in-loop */
    for (a1 = 0; a1 < balance; a1++) {
      const tokenId = await blockchain.nftContract.methods.tokenOfOwnerByIndex(blockchain.account, a1).call()
      tokenIds.push(tokenId)
    }
    /* eslint-enable no-await-in-loop */
    let unstaked = []
    unstaked = tokenIds.slice()
    console.log("beast unstaked = ",unstaked)
    setUnStakedInfo(unstaked)
    setSelectedUnStakedTokenIds([])
    setSelectedStakedTokenIds([])
  }

  const fetchStakedInfo = async () => {
    const tokenIds = []
    const balance = await blockchain.stakingContract.methods.stakingAmount(blockchain.account).call()
    let a = 0
    /* eslint-disable no-await-in-loop */
    for (a = 0; a < balance; a++) {
      const tokenInfo = await blockchain.stakingContract.methods.userInfo(blockchain.account, a).call()
      tokenIds.push(tokenInfo.tokenId)
    }
    /* eslint-enable no-await-in-loop */
    let staked = []
    staked = tokenIds.slice()
    setStakedInfo(staked)
    setSelectedUnStakedTokenIds([])
    setSelectedStakedTokenIds([])
  }

  const fetchIsApprovedForAll = async () => {
    const isApp = await blockchain.nftContract.methods.isApprovedForAll(blockchain.account, blockchain.stakingContract._address).call()
    // console.log("nftContract: ", nftContract)
    // console.log("account: ", account)
    // console.log("stakingContract.address: ", stakingContract.address)
    setIsApproved(isApp)
  }

  const fetchReward = async () => {
    const result = await blockchain.stakingContract.methods.pendingTotalReward(blockchain.account).call()
    setReward(result / (10 ** 9))
    console.log("beast fetchreward =", result)
  }

  const IsSelected = (type, tokenId) => {
    let a = 0
    const list = type === 0 ? selectedUnStakedTokenIds : selectedStakedTokenIds
    for (a = 0; a < list.length; a++) {
      if (list[a] === tokenId) {
        return true
      }
    }
    return false
  }

  const removeItemFromArray = (oldlist, tokenId, ) => {
    const list = oldlist
    let i = 0
    for (i = 0; i < list.length; i++) {
      if (list[i] === tokenId) {
        list[i] = list[list.length - 1]
        list.pop()
        break
      }
    }
    return list
  }

  const unstakedImageClick = async (tokenId, index) => {
    if (await IsSelected(0, tokenId)) {
      const newlist = await removeItemFromArray(
        selectedUnStakedTokenIds,
        tokenId,
      )
      setSelectedUnStakedTokenIds(newlist)
    } else {
      const newlist1 = selectedUnStakedTokenIds;
      newlist1.push(tokenId);
      setSelectedUnStakedTokenIds(newlist1);
    }
    setSizeArrayForStake()
  }

  const stakedImageClick = async (tokenId, index) => {
    if (await IsSelected(1, tokenId)) {
      const newlist = await removeItemFromArray(
        selectedStakedTokenIds,
        tokenId,
      )
      setSelectedStakedTokenIds(newlist)
    } else {
      const newlist1 = selectedStakedTokenIds
      newlist1.push(tokenId)
      setSelectedStakedTokenIds(newlist1)
    }
  }

  const setSizeArrayForStake = async() => {
    console.log("here")
    let i = 0
    const list = [];
    for (i = 0; i < selectedUnStakedTokenIds.length; i++)
    {
      const val = await blockchain.nftContract.methods.getSize(selectedUnStakedTokenIds[i]).call()
      list.push(val);
    }
    console.log("beast sizeArray = ", list)
    setSizeArrayofStakedTokens(list);
  }

  const handleStake = async () => {
    if (!isApproved) {
      console.log("beast: handlestake !isApproved")
      try {
        setpendingTx(true)
        // await setApprovalForAll(signerNftContract, stakingContract, account, true)
        await blockchain.nftContract.methods.setApprovalForAll(CONFIG.STAKING_CONTRACT_ADDRESS, true).send({from: blockchain.account})
        setIsApproved(true)
        setpendingTx(false)
      } catch (error) {
        console.log({error})
        setpendingTx(false)
      }
    } else {
      try {
        setpendingTx(true)
        // await stake(signerStakingContract, account, selectedUnStakedTokenIds, sizeArrayofStakedTokens)
        await blockchain.stakingContract.methods.stake(selectedUnStakedTokenIds, sizeArrayofStakedTokens).send({from: blockchain.account})
        setpendingTx(false)
        setFetchFlag(true);
      } catch (error) {
        console.log('Stake failed',error)
        setpendingTx(false)
      }
    }
  }

  const handleClaim = async () => {
    try {
      setpendingTx(true)
      await blockchain.stakingContract.methods.claim().send({from: blockchain.account})
      setIsApproved(true)
      setpendingTx(false)
    } catch {
      console.log('Claim failed')
      setpendingTx(false)
    }
  }

  const handleUnStake = async () => {
    try {
      setpendingTx(true)
      await blockchain.stakingContract.methods.unstake(selectedStakedTokenIds).send({from: blockchain.account})
      setIsApproved(true)
      setpendingTx(false)
      setFetchFlag(true);
    } catch {
      console.log('UnStake failed')
      setpendingTx(false)
    }
  }

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    if (blockchain.account){
      fetchIsApprovedForAll()
      fetchUnStakedInfo()
      fetchStakedInfo()
      setFetchFlag(false)
    }
    if (blockchain.account)
      fetchReward()
    getData();
  }, [blockchain.account, slowRefresh]);

  return (
    <s.Screen>
    <s.Container
        flex={2}
        // ai={"center"}
        jc={"center"}
        style={{ padding: 24, backgroundColor: "var(--primary)" }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.png" : null}
      >
        <ResponsiveWrapperHeader>
          <StyledButton
            onClick={(e) => {
              e.preventDefault();
              dispatch(connect());
              getData();
            }}
          >
            {walletAddress}
          </StyledButton>
        </ResponsiveWrapperHeader>
       <Container>
         <div className='stacked-wraper'>
            <Fade top>
              <div className='stack-card' style={{textAlign: 'center'}}>
                <h1>Unstaked</h1>               
                <div>
                  <Swiper
                    slidesPerView={2}
                    spaceBetween={10}
                    loop={false}
                    navigation={true}
                    modules={[Navigation]}
                    className="mySwiper"
                  >
                    {unstakedinfo && unstakedinfo.map((tokenId, idx) => {
                      const image = `${baseURI}${tokenId % 4}.png`
                      const isSelected = IsSelected(0, tokenId)
                      return (
                        <SwiperSlide 
                          key={tokenId} 
                          className="mySwiperSlide"
                          onClick={() => unstakedImageClick(tokenId, idx)} >
                            <img
                              className={isSelected ? 'withBorder' : 'noBorder'}
                              src={image}
                              alt=""
                            />
                            <div style={{ color: 'white', textAlign: 'center' }}>
                              {tokenId}
                            </div>
                        </SwiperSlide>
                      )
                    })}
                  </Swiper>   
                  <Button className='stake-button' disabled={pendingTx} onClick={handleStake}>{isApproved ? 'STAKE' : 'APPROVE'}</Button>
                </div>
              </div>
            </Fade>
            <Fade bottom>
              <div className='stack-card' style={{textAlign: 'center'}}>
                <h1>Staked</h1>
                <div>
                  <Swiper
                    slidesPerView={2}
                    spaceBetween={10}
                    // eslint-disable-next-line react/jsx-boolean-value
                    loop={false}
                    // eslint-disable-next-line react/jsx-boolean-value
                    navigation={true}
                    modules={[Navigation]}
                    className="mySwiper"
                    >
                      {stakedinfo && stakedinfo.map((tokenId, idx) => {
                        const image = `${baseURI}${tokenId % 4}.png`
                        const isSelected = IsSelected(1, tokenId)
                        return (
                          <SwiperSlide 
                            key={tokenId} 
                            className="mySwiperSlide"
                            onClick={() => stakedImageClick(tokenId, idx)} >
                              <img
                                className={isSelected ? 'withBorder' : 'noBorder'}
                                src={image}
                                alt=""
                              />
                              <div style={{ color: 'white', textAlign: 'center' }}>
                                {tokenId}
                              </div>
                          </SwiperSlide>
                        )
                      })}
                  </Swiper>
                </div>
                <p className='secondCard-text'>Available to claim: {reward} reward</p>
                <div className='secondCard-button'>
                  <Button className='stake-button' disabled={pendingTx} onClick={handleClaim}>Claim Reward Tokens</Button>
                  <Button className='stake-button unstack-btn' disabled={pendingTx} onClick={handleUnStake}>Unstake</Button>
                </div>
              </div>
            </Fade>
         </div>
       </Container>
    </s.Container>
    </s.Screen>
  );
}

export default App;
